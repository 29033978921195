import React, { useEffect, useState } from 'react';
import { Badge, Button, Input, InputGroup, InputGroupText, Spinner, Table } from 'reactstrap';
import { useHandleModal } from '../hooks/useHandleModal';
import { ApiService } from '../services/apiService';
import { niceDate } from '../services/utility';
import { InfoModal } from './InfoModal';
import ExportQR from './ExportQR';
import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';
import '@uppy/drag-drop/dist/style.min.css';
import '@uppy/progress-bar/dist/style.min.css';
import { Dashboard } from "@uppy/react";
import Uppy from "@uppy/core";
import DropTarget from '@uppy/drop-target';
import XHR from '@uppy/xhr-upload';
import RowForm from './RowForm';
import orangeIcon from '../assets/icons/orange.svg';
import greenIcon from '../assets/icons/green.svg';
import redIcon from '../assets/icons/red.svg';

export const ProjectDetailsRows = (props) => {
    const [pagination, setPagination] = useState();
    const rows = pagination?.data ?? [];
    const [page, setPage] = useState(1);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [query2, setQuery2] = useState('');
    const { project, landingPages, selected, setSelected } = props;
    const modal = useHandleModal({ onClose: (needRefetch) => needRefetch && fetchRows() });
    const [showUpload, setShowUpload] = useState(false);
    const [rowId, setRowId] = useState(undefined);
    const [endPoint, setEndPoint] = useState('https://weq.se/files/upload');
    
    const [pageSize, setPageSize] = useState(20);

    const uppy = React.useMemo(() => {
        return new Uppy({
            restrictions: {
                maxFileSize: 1000 * 1024 * 1024,
                allowedFileTypes: ['video/*'],
                maxNumberOfFiles: 1,
            },
        })
            .use(DropTarget, {
                target: document.body,
            })
            .use(XHR, {
                endpoint: endPoint,
                validateStatus: function (status) {
                    if (status >= 200 && status < 300) {
                        setShowUpload(false);
                        return true;
                    }
                    return false;
                }
            });
    }, [endPoint])

    useEffect(() => {
        if (rowId) {
            const endpointPieces = window.location.href.split('/');
            const endpoint = `${endpointPieces[0]}//${endpointPieces[2]}/files/upload?rowId=${rowId}`;
            setEndPoint(endpoint);
        }
    }, [rowId]);

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
    };

    const deleteRow = (row) => {
        if (window.confirm("Are you sure?")) {
            new ApiService()
                .delete('project/row/' + row.id)
                .catch(() => alert("Something went wrong"))
                .then(c => c && fetchRows());
        }
    }
    const syncRow = (row) => {
        if (window.confirm("Are you sure?")) {
            new ApiService()
                .get('utility/findvideo/' + row.id)
                .catch(() => alert("Something went wrong"))
                .then(c => c && fetchRows());
        }
    }
    useEffect(() => {
        fetchRows();
    }, [project, page, pageSize]);

    const fetchRows = () => {
        if (!project?.id) return;
        setLoading(true);
        setSelected([]);
        new ApiService()
            .post(`project/rows/${project.id}?pageSize=${pageSize}`, { query, query2, page })
            .finally(() => setLoading(false))
            .then(setPagination);
    };
    // Refetch when query changes (with a delay)
    useEffect(() => {
        const id = setTimeout(() => {
            setPage(o => {
                if (o === 1) fetchRows();
                return 1
            })
        }, 200);
        return () => clearTimeout(id)
    }, [query, query2, setPage])
    const editRow = (row) => {
        modal.setItem(row);
        modal.showModal();
    }

    const resetClickCounter = (row) => {
        if (window.confirm("Are you sure you want to reset click counter for this row?") === true) {
            new ApiService()
                .delete('project/row/reset/click/' + row.id)
                .catch(() => alert("Something went wrong"))
                .then(c => c && fetchRows());
        }
    }

    const resetCTAClickCounter = (row) => {
        if (window.confirm("Are you sure you want to reset CTA click counter for this row?") === true) {
            new ApiService()
                .delete('project/row/reset/ctaclick/' + row.id)
                .catch(() => alert("Something went wrong"))
                .then(c => c && fetchRows());
        }
    }

    const resetPlayClickCounter = (row) => {
        if (window.confirm("Are you sure you want to reset Play click counter for this row?") === true) {
            new ApiService()
                .delete('project/row/reset/playclick/' + row.id)
                .catch(() => alert("Something went wrong"))
                .then(c => c && fetchRows());
        }
    }

    const videoLinkShort = (str) => {
        // Have to specify the i route when signed in
        if (window.location.href.includes('localhost'))
            str = str.replace('https://weq.se/', 'https://localhost:44470/i/');
        if (str.includes(".se/i/") || str.includes(".net/i/"))
            return str;
        return str.replace('.se/', '.se/i/').replace('.net/', '.net/i/');
    }
    const niceVideoName = (str) => {
        if (str.length < 10) return str;
        return str.slice(0, 8) + '...';
    }
    const toggleAll = () => {
        setSelected(selected.length === rows.length ? [] : rows.map(r => r.id));
    };

    // Function to toggle an individual checkbox
    const toggleCheckbox = (rowId) => {
        setSelected(selected.includes(rowId) ? selected.filter(id => id !== rowId) : [...selected, rowId]);
    };
    const statuses = (status, icon) => <span className='icofont fs-4 rounded clickable me-1 p-1'
        style={query2 === status ? { border: '2px solid crimson' } : { border: '2px solid transparent' }}
        onClick={() => setQuery2(q => q !== status ? status : '')}>{icon}</span>

    return (
        <div>
            <InputGroup className="py-3 w-25">
                <InputGroupText className='icofont'>&#xed12;</InputGroupText>
                <Input placeholder='Search...' value={query} onChange={e => setQuery(e.target.value)} />
                <Button color={query ? 'danger' : 'light'} className='icofont'
                    onClick={() => setQuery('')}>&#xeee4;</Button>
            </InputGroup>
            <div>
                {statuses('STATUS_WAITING', <span className='text-secondary'>&#xe821;</span>)}
                {statuses('STATUS_READY', <span className='text-warning'>&#xeedc;</span>)}
                {statuses('STATUS_SENT', <span className='text-success'>&#xf00e;</span>)}
                <label htmlFor='pageSize'
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem', verticalAlign: 'middle' }}>Rows per
                    page:</label>
                <select
                    id='pageSize'
                    style={{ marginBottom: '0.5rem', verticalAlign: 'middle' }}
                    value={pageSize}
                    onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
                >
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                </select>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>
                            <Input type='checkbox' style={{ minHeight: '1.5rem', maxWidth: '2rem', verticalAlign: 'middle' }}
                                checked={selected.length === rows.length}
                                indeterminate={selected.length > 0 && selected.length < rows.length === false ? 'false' : 'true'}
                                onChange={toggleAll}
                            />
                        </th>
                        <th>VideoName</th>
                        <th>SendDate</th>
                        <th>Created</th>
                        {project?.sendSms ? <th>Phone</th> : ''}
                        {project?.sendEmail ? <th>Email</th> : ''}
                        {project?.sendProvider ? <th>Record Id</th> : ''}
                        <th>VideoLink Short</th>
                        <th className='text-center'>Clicks</th>
                        <th className='text-center'>CTA Clicks</th>
                        <th className='text-center'>Play Clicks</th>
                        <th className='text-center'>Status</th>
                        <th className='text-center'>SmsStatus</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        loading ? <tr style={{ textAlign: 'center', backgroundColor: 'transparent' }}>
                            <td colSpan={100}><Spinner color='primary' /></td>
                        </tr>
                            :
                            rows.map(r =>
                                <tr key={r.id} className='tr-td-vc'>
                                    <td>
                                        <Input type='checkbox' style={{ minHeight: '2rem' }}
                                            checked={selected.includes(r.id)}
                                            onChange={() => toggleCheckbox(r.id)}
                                        />
                                    </td>
                                    <td title={r.videoName}>{r.videoLink ? <a href={r.videoLink} target='_blank'
                                        rel='noreferrer'>{niceVideoName(r.videoName)}</a> : niceVideoName(r.videoName)}</td>
                                    <td>{niceDate(r.sendDate)}</td>
                                    <td>{niceDate(r.created)}</td>
                                    {project?.sendSms ? <td>{r.phone}</td> : ''}
                                    {project?.sendEmail ? <td>{r.email}</td> : ''}
                                    {project?.sendProvider ? <td>{r.externalIdentifier}</td> : ''}
                                    <td>{r.videoLinkShort ? <a href={videoLinkShort(r.videoLinkShort)} target='_blank'
                                        rel='noreferrer'>{r.videoLinkShort}</a> : ''}</td>
                                    <td className="fs-5 text-center p-0 m-0">
                                        {r.clicks ? <Badge title={niceDate(r.firstClick)} color='secondary'
                                            onClick={() => resetClickCounter(r)}
                                            style={{ width: 35, cursor: 'pointer' }}>{r.clicks}</Badge> : ''}
                                    </td>
                                    <td className="fs-5 text-center p-0 m-0">
                                        {r.ctaClicks ? <Badge title={niceDate(r.firstCtaClick)} color='secondary'
                                            onClick={() => resetCTAClickCounter(r)}
                                            style={{ width: 35, cursor: 'pointer' }}>{r.ctaClicks}</Badge> : ''}
                                    </td>
                                    <td className="fs-5 text-center p-0 m-0">
                                        {r.playButtonClicks ? <Badge color='secondary'
                                            onClick={() => resetPlayClickCounter(r)}
                                            style={{ width: 35, cursor: 'pointer' }}>{r.playButtonClicks}</Badge> : ''}
                                    </td>
                                    <td className='text-center'>
                                        {(!r.sent && r.videoLinkShort) &&
                                            <span className='icofont text-warning fs-4'>&#xeedc;</span>
                                        }
                                        {r.sent &&
                                            <span className='icofont text-success fs-4'>&#xf00e;</span>
                                        }
                                        {(!r.sent && !r.videoLinkShort) &&
                                            <span className='icofont text-secondary fs-4'>&#xe821;</span>
                                        }
                                    </td>
                                    <td className="text-center">
                                        {/* Pending - Orange SVG */}
                                        {r.smsSentOutStatus === 0 && (
                                            <img
                                                src={orangeIcon}
                                                alt="Pending"
                                                className="icofont fs-4 text-secondary"
                                                style={{ width: '40px', height: '40px' }}
                                                title="Pending"
                                            />
                                        )}
                                        {/* Delivered - Green SVG */}
                                        {r.smsSentOutStatus === 1 && (
                                            <img
                                                src={greenIcon}
                                                alt="Delivered"
                                                className="fs-4"
                                                style={{ width: '40px', height: '40px' }}
                                                title="Delivered"
                                            />
                                        )}
                                        {/* Failed - Red SVG */}
                                        {r.smsSentOutStatus === 2 && (
                                            <img
                                                src={redIcon}
                                                alt="Failed"
                                                className="fs-4"
                                                style={{ width: '40px', height: '40px' }}
                                                title={r.infoLogs && r.infoLogs.length > 0 ? r.infoLogs[0].message : "No log available"}
                                            />
                                        )}
                                    </td>

                                    <td><span className='clickable icofont' onClick={() => editRow(r)}>&#xec55;</span></td>
                                    <td><span className='clickable icofont' onClick={() => deleteRow(r)}>&#xee09;</span>
                                    </td>
                                    <td><span title='Sync with Vimeo and Send out' className='clickable icofont'
                                        onClick={() => syncRow(r)}>&#xefd1;</span></td>
                                    <td><ExportQR url={r.videoLinkShort} name={r.videoName} /></td>
                                    <td><span className='clickable icofont fs-4'
                                        onClick={() => {
                                            setRowId(r.id)
                                            setShowUpload(true)
                                        }}>&#xf01c;</span></td>
                                </tr>
                            )}
                </tbody>
            </Table>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', textAlign: 'center' }}>
                <span>Total: {pagination?.count ?? 0} rows</span>
                <span>Page {page} / {pagination?.totalPages ?? 1}</span>
                <span>
                    <Button onClick={() => setPage(p => p - 1)} disabled={page <= 1} color='outline-primary'
                        style={{ marginRight: '1rem' }} className='icofont'>&#xea9d;</Button>
                    <Button onClick={() => setPage(p => p + 1)} disabled={page >= pagination?.totalPages ?? 1}
                        color='outline-primary' style={{ marginRight: '1rem' }} className='icofont'>&#xeaa0;</Button>
                </span>
            </div>
            <InfoModal
                handleClose={modal.closeModal}
                show={modal.show}
                headerText={modal.item?.videoName}
                body={<RowForm row={modal.item} project={project} landingPages={landingPages} success={() => {
                    modal.closeModal();
                    fetchRows();
                }} />}
            />

            <InfoModal
                handleClose={() => setShowUpload(false)}
                show={showUpload}
                headerText={<div className={"d-flex justify-content-between align-items-center w-100"}>
                    <span className={"me-3"}>
                        Upload Video
                    </span>
                    <Button onClick={() => setShowUpload(false)}
                        color='primary'
                        size={'sm'}
                    >
                        Close
                    </Button>
                </div>}
                body={
                    <Dashboard
                        uppy={uppy}
                        target={'#drag-drop-area'}
                        proudlyDisplayPoweredByUppy={false}
                        showProgressDetails={true}
                        height={400}
                    />
                }
            />
        </div>
    );
}
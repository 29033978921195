import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, InputGroup, InputGroupText, Label, Spinner } from 'reactstrap';
import { ApiService } from '../services/apiService';
import { localeDate, utcDate } from '../services/utility';

const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
const RowForm = ({ project, landingPages, row, success }) => {
    const isEdit = Boolean(row);
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);
    const [showDefaults, setShowDefaults] = useState(false);
    const [imageUrl, setImageUrl] = useState(row?.imageUrl);
    const [imagebase64, setImageBase64] = useState('');
    const [phoneRequired] = useState(project.sendSms ? { required: true } : {});
	const [emailRequired] = useState(project.sendEmail ? { required: true } : {});
	const [recordRequired] = useState(project.sendProvider ? { required: true } : {});
    const navigate = useNavigate();

    landingPages = landingPages ?? [];

    useEffect(() => {
        let initialVals = {
            sendDate: localeDate(row?.sendDate ?? ''),
            phone: row?.phone ?? '',
            email: row?.email ?? '',
            landingPageId: row?.landingPageId ?? '',
            externalIdentifier: row?.externalIdentifier ?? '',
            ...project.columns.reduce((acc, col) => ({
                ...acc,
                [col.name]: row ? row[col.name] : col.defaultValue
            }), {})
        };
        setValues(initialVals);

    }, [row, project.columns]);

    const handleChange = (key, e) => {
        if (key === "imageBase64") {
            handleImageChange(e);           
        } else {
            setValues(prev => ({ ...prev, [key]: e.target.value }));
        }
    };
    const set = (input, e) => {
        setValues(o => ({ ...o, [input]: e.target.value }))
    }

    const getValueForColumn = (column) => {
        if (isEdit) {
            const columnValue = row.columnValues.find(val => val.columnId === column.id);
            return columnValue ? columnValue.value : column.defaultValue;
        }
        else {
            return values[column.name];
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageBase64(reader.result);
                setImageUrl(reader.result)
            };
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setImageUrl(null);
        setImageBase64(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (values['sendDate'] || window.confirm('You never specified a send date. Project default send date will be used. Are you sure?')) {
            setLoading(true);

            if (values?.sendDate) values.sendDate = utcDate(values.sendDate);

            const dataEntries = Object.keys(values)
                .filter(key => project?.columns.some(obj => obj.name === key))
                .map(key => ({ "Key": key, "Value": values[key] }));

            const data = {
                id: row?.id || null,
                projectId: project.id,
                imageUrl: imageUrl,
                imageBase64: imagebase64,
                ...values,
                values: dataEntries
            };

            const apiUrl = 'project/row';
            const method = isEdit ? 'put' : 'post';
            try {
                
                const response = await new ApiService()[method](apiUrl, data);

                if (response?.id) {
                    if (isEdit) {
                        success();
                    } 
                    else
                    {
                        navigate(`/pjs/${project.id}`);
                    }
                } else {
                    throw new Error('No valid response ID received');
                }
            } catch (error) {
                alert('Something went wrong');
            } finally {
                setLoading(false);
            }
        }
    };

    const removeButtonStyle = {
        position: 'relative',
        top: '5px',
        right: '5px',
        padding: '2px 5px',
        fontSize: '12px',
        lineHeight: '1',
        borderRadius: '50%',
        color: '#fff',
        backgroundColor: '#dc3545', // Bootstrap's btn-danger color
        border: 'none',
        cursor: 'pointer'
    };

    if (loading) {
        return <Spinner />;
    }

    return (
        <Form onSubmit={handleSubmit}>
            {/* Standard fields */}
            <InputGroup className="mb-2">
                <Label>SendDate
                    <Input type='datetime-local' required value={values.sendDate} onChange={e => handleChange('sendDate', e)} />
                </Label>
            </InputGroup>
            <InputGroup className="mb-2">
                <Label>Phone
                    <Input value={values.phone} type="tel" {...phoneRequired} placeholder='Phone' onChange={e => handleChange('phone', e)} />
                </Label>
            </InputGroup>
            <InputGroup className="mb-2">
                <Label>Email
                    <Input value={values.email} type="email" {...emailRequired} placeholder='Email' onChange={e => handleChange('email', e)} />
                </Label>
            </InputGroup>
            <InputGroup className="mb-2">
                <Label>Record ID
                    <Input value={values.externalIdentifier} type="text" {...recordRequired} placeholder='Record ID' onChange={e => handleChange('externalIdentifier', e)} />
                </Label>
            </InputGroup>
            <InputGroup className="mb-2">
                <Label>Landing Page
                    <Input type={'select'} value={values.landingPageId} placeholder='Landing Page' onChange={e => handleChange('landingPageId', e)} style={{ width: '200px' }}>
                        <option value={''}>- Select -</option>
                        {landingPages.map((o, i) => <option key={o.key} value={o.key}>{o.value}</option>)}
                    </Input>
                </Label>
            </InputGroup>
            <InputGroup className="mb-2">
                <Label>Row Image
                    <Input type="file" onChange={(e) => handleChange('imageBase64', e)} />
                </Label>
            </InputGroup>
            <div style={{ position: 'relative', display: 'inline-block' }}>
                {imageUrl && (
                    <button style={removeButtonStyle} onClick={handleRemoveImage}>X</button>
                )}
                {imageUrl && (
                    <div style={{
                        border: '1px solid #ddd',
                        padding: '10px',
                        maxWidth: '60%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '4px'
                    }}>
                        <img src={imageUrl} alt="Project" style={{ maxWidth: '100%', maxHeight: '150px' }} />
                    </div>
                )}
            </div>

            <InputGroup className='mb-2' style={{ display: 'flex', justifyContent: 'right', maxWidth: '40rem' }}>
                <Button color='outline-secondary' onClick={() => setShowDefaults(o => !o)}>{showDefaults ? 'Hide Defaults' : 'Show Defaults'}</Button>
            </InputGroup>
            {project?.columns.map((c, i) =>
                ((!c.defaultValue || values[c.name]) || showDefaults) &&
                <InputGroup key={i} style={{ maxWidth: '40rem' }} className='mb-1'>
                    <InputGroupText style={{ fontFamily: 'monospace' }}>{alphabet[i]}1</InputGroupText>

                    <Input disabled={c.name === 'output'} placeholder={c.name} defaultValue={getValueForColumn(c)}
                        onChange={(e) => set(c.name, e)} />
                    <InputGroupText>{c.name}</InputGroupText>
                </InputGroup>
            )}
            <Button className="mt-3" color="primary" type="submit">{isEdit ? 'Update' : 'Append'} Row</Button>
        </Form>
    );
};

export default RowForm;

import React, {Component, Fragment} from 'react';
import {NavItem, NavLink} from 'reactstrap';
import {Link} from 'react-router-dom';
import authService from './AuthorizeService';
import {ApplicationPaths} from './ApiAuthorizationConstants';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const {isAuthenticated, userName} = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = `${ApplicationPaths.LogOut}`;
            const logoutState = {local: true};
            return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
        }
    }

    authenticatedView(userName, profilePath, logoutPath, logoutState) {
        return (<Fragment>
            {/* <NavItem>
        <NavLink tag={Link} className="text-light" to={profilePath}>Hello {userName}</NavLink>
      </NavItem> */}
            <NavItem>
                <NavLink tag={Link} className="text-white" to="/">Home</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-white" to="/links">Links</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-white" to="/pjs">Projects</NavLink>
            </NavItem>            
            <NavItem>
                <NavLink replace tag={Link} className="text-white" to={logoutPath}
                         onClick={() => window.location.href = "/Identity/Account/logout"} state={logoutState}>Sign
                    out</NavLink>
                {/* <NavLink replace tag={Link} className="text-light" to={logoutPath} state={logoutState}>Sign out</NavLink> */}
            </NavItem>
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            {/* <NavItem>
        <NavLink tag={Link} className="text-light" to={registerPath}>Register</NavLink>
      </NavItem> */}
            <NavItem>
                <NavLink tag={Link} className="text-white" to={loginPath}>Sign in</NavLink>
            </NavItem>
        </Fragment>);
    }
}

import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button} from 'reactstrap';
import {ApiService} from '../services/apiService';
import {ExportProject} from './ExportProject';
import {ProjectDetailsRows} from './ProjectDetailsRows';
// import { ExportProjectQR } from './ExportProjectQR';

export const ProjectDetails = (props) => {
    const project = props.project;
    const landingPages = props.landingPages;
    const navigate = useNavigate();
    const [selected, setSelected] = useState([]);
    const del = () => {
        if (window.confirm("Are you sure?")) {
            new ApiService().delete('project/' + project.id)
                .then(p => p && navigate('/pjs'));
        }
    }
    const delAllRows = () => {
        if (window.confirm(`Are you sure you want to delete ${selected.length ? selected.length : 'All'} rows? \n\n(Important: the rows won't be deleted from Goodle sheets. Do that manually, or create a new sheet and update the project sheet link.)`)) {
            new ApiService().delete('project/delete-all-rows', {projectId: project.id, rowIds: selected})
                .then(p => p && props.refetchProject());
        }
    }
    const downloadAllQRCodes = () => {
        if (window.confirm(`Are you sure you want to downlaod ${selected.length ? selected.length : 'All'} QR codes of rows?`)) {
            new ApiService().post('project/get-qrcodes-rowsurl', { projectId: project.id, rowIds: selected })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "qrcodes.zip");
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        }
    }
    const resetAllClicks = () => {
        if (window.confirm(`Are you sure you want to reset all clicks including CTA clicks for rows?`)) {
            new ApiService().delete('project/reset-clicks-project/'+project.id, { projectId: project.id, rowIds: selected })
                .then(p => p && navigate('/pjs/' + project.id));
        }
    }


    return (
        <div className={"overflow-auto"}>
            <div>
                {/* <Button color='outline-secondary' size='sm' onClick={props.refetchProject}>
          <span className='icofont'>&#xefd1;</span>
        </Button> */}
                <div style={{float: 'right'}}>
                    <span className='me-4'>Total Clicks: {project?.clicks ?? '-'}</span>
                    <Button color='outline-primary' className='me-2' size='sm' onClick={resetAllClicks}>
                        <small className='icofont pe-1'>&#xee0b;</small> Reset Clicks
                    </Button>
                    <Button color='outline-primary' className='me-2' size='sm' onClick={downloadAllQRCodes}>
                        <small className='icofont pe-1'>&#xe96e;</small> Download QR Codes
                    </Button>
                    {/* <ExportProjectQR project={project} /> */}
                    <ExportProject project={project} />
                    <Button className='me-1' color='outline-danger' size='sm' onClick={delAllRows}>
                        <small className='icofont pe-1'>&#xee09;</small> Delete {selected.length ? selected.length : 'All'} Rows
                    </Button>
                    <Button color='outline-danger' size='sm' onClick={del}>
                        <small className='icofont pe-1'>&#xee09;</small> Delete Project
                    </Button>
                </div>
            </div>
            <ProjectDetailsRows project={props.project} landingPages={landingPages} selected={selected} setSelected={setSelected}/>
        </div>
    );
}